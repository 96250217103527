import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useEventListener } from "./useEventlistener";

export enum Device {
  Mobile,
  Tablet,
  Desktop,
}

export const useDevice = () => {
  const [device, setDevice] = useState<Device>(Device.Desktop);
  const createTestElement = useCallback(() => {
    const element = document.getElementById("deviceTester") || document.createElement("div");
    element.setAttribute("id", "deviceTester");
    element.classList.add(..."opacity-0 fixed top-0 left-0 z-10 h-0 lg:h-px xl:w-px pointer-events-none".split(" "));
    document.body.appendChild(element);
    return element;
  }, []);

  const testElement = useRef(createTestElement());

  const checkDevice = useCallback(() => {
    console.log("checking");
    testElement.current = createTestElement();
    setDevice(() => {
      if (testElement.current.clientWidth > 0 && testElement.current.clientHeight > 0) return Device.Desktop;
      else if (testElement.current.clientHeight > 0) return Device.Tablet;
      else return Device.Mobile;
    });
  }, [createTestElement]);

  useLayoutEffect(() => {
    checkDevice();
  }, [checkDevice]);

  useEventListener("resize", checkDevice);

  return device;
};
