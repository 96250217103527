/* eslint-disable quotes */
import { memo, useCallback, useMemo } from "react";
import TastaturBackspaceButton from "./buttons/TastaturBackspaceButton";
import TastaturButton from "./buttons/TastaturButton";
import TastaturEnterButton from "./buttons/TastaturEnterButton";
import TastaturShiftButton from "./buttons/TastaturShiftButton";
import TastaturSpaceButton from "./buttons/TastaturSpaceButton";
import TastaturSpecialCharButton from "./buttons/TastaturSpecialCharButton";
import TastaturSwitchButton from "./buttons/TastaturSwitchButton";
import { TastaturSpecialKeys } from "./Tastatur";
import useTastaturContext from "./useTastaturContext";

type TastaturTextProps = {
  onKeyPress: (key: string | TastaturSpecialKeys) => void;
};

const TastaturText = ({ onKeyPress, ...props }: TastaturTextProps) => {
  const { isShifted, mode, isShowingSpecialKeys } = useTastaturContext();

  const firstRowKeys: (string | TastaturSpecialKeys)[] = useMemo(
    () =>
      mode === "Text"
        ? ["q", "w", "e", "r", "t", "z", "u", "i", "o", "p", "ü"]
        : isShowingSpecialKeys
        ? ["[", "]", "{", "}", "#", "%", "^", "*", "+", "="]
        : Array(10)
            .fill(null)
            .map((_, c) => (c < 9 ? c + 1 + "" : "0")),
    [isShowingSpecialKeys, mode]
  );

  const secondRowKeys: (string | TastaturSpecialKeys)[] = useMemo(
    () =>
      mode === "Text"
        ? ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ö", "ä"]
        : isShowingSpecialKeys
        ? ["_", "\\", "|", "~", "<", ">", "$", "£", "¥", "•"]
        : ["-", "/", ":", ";", "(", ")", "€", "&", "@", '"'],
    [isShowingSpecialKeys, mode]
  );

  const thirdRowKeys: (string | TastaturSpecialKeys)[] = useMemo(
    () =>
      mode === "Text"
        ? [TastaturSpecialKeys.Shift, "y", "x", "c", "v", "b", "n", "m", TastaturSpecialKeys.Backspace]
        : [TastaturSpecialKeys.SpecialChars, ".", ",", "?", "!", "´", TastaturSpecialKeys.Backspace],
    [mode]
  );

  const fourthRowKeys: (string | TastaturSpecialKeys)[] = useMemo(
    () => [TastaturSpecialKeys.Switch, TastaturSpecialKeys.Space, TastaturSpecialKeys.Enter],
    []
  );

  const keyboardKeys: (string | TastaturSpecialKeys)[] = useMemo(
    () => [...firstRowKeys, ...secondRowKeys, ...thirdRowKeys, ...fourthRowKeys],
    [firstRowKeys, fourthRowKeys, secondRowKeys, thirdRowKeys]
  );

  const relayKeyPress = useCallback(
    (key: string | TastaturSpecialKeys) => () => {
      onKeyPress(key);
    },
    [onKeyPress]
  );

  const keyButtons = useMemo(
    () =>
      keyboardKeys.map((key, idx) => {
        const keyHandler = relayKeyPress(key);
        return key === TastaturSpecialKeys.Shift ? (
          <TastaturShiftButton key="Shift" onPress={keyHandler} isShifted={isShifted} />
        ) : key === TastaturSpecialKeys.Backspace ? (
          <TastaturBackspaceButton key="Backspace" onPress={keyHandler} />
        ) : key === TastaturSpecialKeys.Space ? (
          <TastaturSpaceButton
            key="Space"
            onPress={keyHandler}
            className={mode === "Text" ? "col-span-7" : "col-span-6"}
          />
        ) : key === TastaturSpecialKeys.Switch ? (
          <TastaturSwitchButton key="Switch" onPress={keyHandler}>
            {mode === "Text" ? "123" : "ABC"}
          </TastaturSwitchButton>
        ) : key === TastaturSpecialKeys.Enter ? (
          <TastaturEnterButton key="Enter" onPress={keyHandler} />
        ) : key === TastaturSpecialKeys.SpecialChars ? (
          <TastaturSpecialCharButton key="SpecialChar" onPress={keyHandler}>
            {isShowingSpecialKeys ? "123" : "#+="}
          </TastaturSpecialCharButton>
        ) : (
          <TastaturButton
            className={mode === "Signs" && key === "?" ? "col-span-2 h-full" : undefined}
            key={key}
            onPress={keyHandler}
          >
            {!isShifted ? key : key.toString().toLocaleUpperCase()}
          </TastaturButton>
        );
      }),
    [isShifted, isShowingSpecialKeys, keyboardKeys, mode, relayKeyPress]
  );
  return (
    <div
      className={`inline-grid justify-center items-stretch min-h-0 ${
        mode === "Text" ? "grid-cols-11" : "grid-cols-10"
      } w-full flex-1`}
    >
      {keyButtons}
    </div>
  );
};
export default memo(TastaturText);
