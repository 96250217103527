enum ActionType {
  Empty,
  Query,
  ServerQuery,
  Command,
  BackendCall,
  Click,
  Component,
  Hook,
}
export default ActionType;
