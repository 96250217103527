import { memo } from "react";
import TastaturText from "./TastaturText";

export enum TastaturArt {
  "Text",
  "Numeric",
}

export enum TastaturSpecialKeys {
  "Shift" = "Shift",
  "Switch" = "Switch",
  "Enter" = "Enter",
  "Send" = "Send",
  "Space" = "Space",
  "Backspace" = "Backspace",
  "SpecialChars" = "SpecialChars",
}

type TastaturProps = {
  type: TastaturArt;
  onKeyPress: (key: string | TastaturSpecialKeys) => void;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};

const Tastatur = (props: TastaturProps) => {
  return (
    <>
      <div
        className={`fixed bottom-[20rem] left-0 inline-flex w-full bg-gray-50 min-h-0 h-10 border-t-[0.5px] border-gray-400 transition-all ease-in-out duration-200 p-1 justify-between items-center content-start z-50 transform ${
          props.isOpen ? "translate-y-0" : "translate-y-[22.5rem]"
        }`}
      >
        <span></span>
        <button
          type="button"
          className="text-blue-500 underline px-3 h-full font-semibold text-sm"
          onClick={props.onClose}
        >
          Fertig
        </button>
      </div>
      <div
        key="tastaturcontainer"
        className={`fixed bottom-0 left-0 right-0 inline-flex justify-start items-stretch content-start min-h-0 w-full h-[20rem] bg-gray-300 p-1.5 pb-12 overflow-hidden overscroll-contain transition-all ease-in-out duration-200 z-50 ${
          props.isOpen ? "translate-y-0" : "translate-y-full pointer-events-none"
        }`}
      >
        {props.type === TastaturArt.Text ? <TastaturText onKeyPress={props.onKeyPress} key="texttastatur" /> : null}
      </div>
    </>
  );
};
export default memo(Tastatur);
