import { FC } from "react";
import TastaturButton from "./TastaturButton";

type TastaturSwitchButtonProps = {
  onPress: () => void;
};

const TastaturSwitchButton: FC<TastaturSwitchButtonProps> = (props) => {
  return (
    <TastaturButton
      onPress={props.onPress}
      innerClassName={"h-full text-gray-700 bg-gray-400"}
      className="col-span-2 h-full"
    >
      {props.children}
    </TastaturButton>
  );
};
export default TastaturSwitchButton;
