import { RedirectRequest } from "@azure/msal-browser";
import { AuthenticationResult, AuthError } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useToggle } from "./useToggle";

type AuthRedirectHandlerProps = {
  config: RedirectRequest;
  invokeRequestOnLoad?: boolean;
  continueOnError?: (error: AuthError | null) => boolean;
};

const useAuthRedirectHandler = ({
  config,
  continueOnError: checkErrorValid,
  invokeRequestOnLoad,
}: AuthRedirectHandlerProps) => {
  const { instance } = useMsal();

  const [result, setResult] = useState<AuthenticationResult | null>(null);
  const [error, setError] = useState<AuthError | null>(null);

  const [isChecking, , startChecking, stopChecking] = useToggle(true);

  useLayoutEffect(() => {
    startChecking();
    instance
      .handleRedirectPromise()
      .then((resp) => {
        if (resp) {
          setError(() => {
            setResult(resp);
            return null;
          });
        }
      })
      .catch((e) => {
        const error: AuthError | null = e as any;
        console.error(error);
        if (error) {
          setResult(() => {
            setError(error);
            return null;
          });
        }
      })
      .finally(() => {
        stopChecking();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.handleRedirectPromise, startChecking, stopChecking]);

  useLayoutEffect(() => {
    if (invokeRequestOnLoad && !isChecking && (checkErrorValid ? checkErrorValid(error) : !error) && !result) {
      instance.loginRedirect({ ...config });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkErrorValid, config, error, instance.loginRedirect, invokeRequestOnLoad, isChecking, result]);

  return { isChecking, result, error };
};
export default useAuthRedirectHandler;
