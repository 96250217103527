import { createContext } from "react";
import { TastaturArt, TastaturSpecialKeys } from "./Tastatur";

type TastaturMode = "Text" | "Signs";

type Context = {
  internalText: string | null;
  mode: TastaturMode;
  isShifted: boolean;
  focusedInputId: string | null;
  setFocusedInputId: (id: string | null) => void;
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  toggleShift: () => void;
  switchMode: () => void;
  onKeyPress: (key: string | TastaturSpecialKeys) => void;
  type: TastaturArt;
  setType: (type: TastaturArt) => void;
  setInternalText: (text: string | null) => void;
  isShowingSpecialKeys: boolean;
  setIsShowingSpecialKeys: (is: boolean) => void;
};

const TastaturContext = createContext<Context>({
  internalText: null,
  mode: "Text",
  isShifted: false,
  focusedInputId: null,
  setFocusedInputId: (...args) => console.log("not implemented", args),
  isVisible: false,
  hide: (...args) => console.log("not implemented", args),
  show: (...args) => console.log("not implemented", args),
  toggleShift: (...args) => console.log("not implemented", args),
  switchMode: (...args) => console.log("not implemented", args),
  onKeyPress: (...args) => console.log("not implemented", args),
  type: 0,
  setType: (...args) => console.log("not implemented", args),
  setInternalText: (...args) => console.log("not implemented", args),
  isShowingSpecialKeys: false,
  setIsShowingSpecialKeys: (...args) => console.log("not implemented", args),
});

export default TastaturContext;
