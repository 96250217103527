import { AuthError, InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import AuthConfig from "../../../AuthConfig";
import { useSavedAccount } from "../../../hooks/useSavedAccount";
import { LoginViewTypes } from "./LoginViewTypes";

const AuthRequest = {
  ...AuthConfig.loginRequest,
};

export const useLoginViewModel = (props: LoginViewTypes.Props) => {
  const { replace } = useHistory();
  const { instance } = useMsal();
  const [acc, setAcc] = useSavedAccount();
  const account = useAccount(instance.getActiveAccount() || acc || instance.getAllAccounts().find((c) => c) || {});

  const config = useMemo(
    () => ({
      ...AuthRequest,
      account: account || undefined,
      loginHint:
        account?.username ??
        ((account?.idTokenClaims as any)?.emails
          ? (account?.idTokenClaims as any)?.emails[0] ?? undefined
          : undefined) ??
        undefined,
    }),
    [account]
  );

  const { error, result, login } = useMsalAuthentication(InteractionType.Silent, config);

  const navigateToForgotPassword = useCallback(() => {
    replace("/resetPassword");
  }, [replace]);

  const handlePasswordReset = useCallback(
    (error: AuthError) => {
      if (
        error.message.includes("AADB2C90118") ||
        error.errorCode.includes("AADB2C90118") ||
        error.errorMessage.includes("AADB2C90118")
      ) {
        navigateToForgotPassword();
        return true;
      } else {
        try {
          const json = JSON.stringify(error);
          if (json.toLocaleLowerCase().includes("password") && json.toLocaleLowerCase().includes("forgotten")) {
            navigateToForgotPassword();
            return true;
          } else {
            throw new Error(`${error}`);
          }
        } catch (error) {
          return false;
        }
      }
    },
    [navigateToForgotPassword]
  );

  const handleUserCancelled = useCallback(
    (error: AuthError) => {
      if (error.name.includes("user_cancelled") || error.errorCode.includes("user_cancelled")) {
        replace("/cancelledLogin");
        return true;
      } else return false;
    },
    [replace]
  );

  useEffect(() => {
    if (result) {
      setAcc(result.account);
      replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.setActiveAccount, result]);

  useEffect(() => {
    if (error) {
      if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect);
      } else {
        let redirect = handlePasswordReset(error);
        if (!redirect) redirect = handleUserCancelled(error);
        if (!redirect) window.alert(error);
      }
    }
  }, [error, handlePasswordReset, handleUserCancelled, login]);

  useEffect(() => {
    const to = setTimeout(() => {
      instance.logout({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });
    }, 5000);
    return () => {
      if (to) clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.logout]);

  return {};
};
