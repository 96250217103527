import { FC } from "react";
import { Heroicons } from "../../Heroicon/Heroicon";
import TastaturButton from "./TastaturButton";

type TastaturEnterButtonProps = {
  onPress: () => void;
};

const TastaturEnterButton = (props: TastaturEnterButtonProps) => {
  return (
    <TastaturButton
      onPress={props.onPress}
      innerClassName={"h-full text-gray-700 bg-gray-400"}
      className="col-span-2 h-full"
    >
      <Heroicons.Outline.ArrowNarrowRightIcon className="w-8 h-8 mx-auto" />
    </TastaturButton>
  );
};
export default TastaturEnterButton;
