import { InteractionStatus } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { AIReactCustomEvent } from "@microsoft/applicationinsights-react-js/types/useTrackEvent";
import * as IDB from "idb-keyval";
import {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { reactPlugin } from "./AppInsights";
import AppInsightsWrapper from "./appInsights/AppInsightsWrapper";
import SettingsProvider, { useSettings } from "./clientApi/SettingsProvider";
import ActionProgress from "./clientApi/tracking/ActionProgress";
import ClientAction from "./clientApi/tracking/Actions";
import ActionType from "./clientApi/tracking/ActionType";
import { LoadingIndicator } from "./components/LoadingIndicator";
import TastaturContextProvider from "./components/tastatur/TastaturContextProvider";
import { Device, useDevice } from "./hooks/useDevice";
import { useSavedAccount } from "./hooks/useSavedAccount";
import { useServiceWorker } from "./hooks/useServiceworker";
import { UnauthenticatedMainView } from "./views/Unauthenticated/main/UnauthenticatedMainView";
import { ViewLoader } from "./views/ViewLoader/viewLoader";
import appPackage from "../package.json";
import { addProtocol } from "./hooks/useProtocol";
import useDebugConnection from "./hooks/RemoteDebug/useDebugConnection";
import DebugContext from "./hooks/RemoteDebug/DebugContext";
import useDebugConnections from "./hooks/RemoteDebug/useDebugConnections";
import { useToasterHandler } from "./components/ToasterHandler/useToasterHandler";

const AuthenticatedView = lazy(() => import("./views/Authenticated/AuthenticatedView"));

const STEP_COUNT_STORAGE = "STEP_COUNT";

export let store: IDB.UseStore | null = null;

const generateEmptyClientApiAction: () => ClientAction = () => {
  const now = new Date();
  return {
    data: "Empty Run",
    identifier: "Empty Identifier",
    timestamp: now,
    timestampString: now.toISOString(),
    type: ActionType[ActionType.Empty] as keyof typeof ActionType,
    isError: false,
    progress: ActionProgress[ActionProgress.Started] as keyof typeof ActionProgress,
    currentUrl: window?.location?.href,
  };
};

const emptyClientApiAction = generateEmptyClientApiAction();
export const TrackContext = createContext<AIReactCustomEvent<ClientAction>>(console.log);

export const AppShell = () => {
  const actionRef = useRef<ClientAction>(emptyClientApiAction);
  const trackEvent = useTrackEvent<ClientAction>(reactPlugin, "Client_Action", actionRef.current, false);
  const fixHeight = useCallback(() => {
    if ("resizeTo" in window && typeof window.resizeTo === "function") {
      window.resizeTo(window.screen.availWidth, window.screen.availHeight);
    }
  }, []);

  useLayoutEffect(() => {
    const observer = new MutationObserver(fixHeight);
    observer.observe(document.documentElement, { subtree: true, attributes: true });
    return () => {
      observer.disconnect();
    };
  }, [fixHeight]);

  const { inProgress } = useMsal();
  const [savedAccount] = useSavedAccount();
  const account = useAccount({
    username: savedAccount?.username,
    homeAccountId: savedAccount?.homeAccountId,
    localAccountId: savedAccount?.localAccountId,
  });

  const isAuthenticated = useIsAuthenticated(account || undefined);

  useLayoutEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      const count = localStorage.getItem(STEP_COUNT_STORAGE);
      const updatedCount = +(count ?? 0) + 1 + "";
      if (+updatedCount < 15) {
        localStorage.setItem(STEP_COUNT_STORAGE, updatedCount);
      } else {
        const confirm = window.confirm(
          "Entschuldigung\r\nEs wurde eine Login-Schleife erkannt\r\nFalls es sich hierbei um einen Fehler handelt, drücken sie auf Abbrechen/Cancel"
        );
        if (confirm) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else if (isAuthenticated) {
      const timeout = setTimeout(() => {
        localStorage.removeItem(STEP_COUNT_STORAGE);
      }, 5000);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [inProgress, isAuthenticated]);

  const sw = useServiceWorker();
  useLayoutEffect(() => {
    if (isAuthenticated) {
      sw.register();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sw.register, isAuthenticated]);

  const device = useDevice();
  const Settings = useSettings();
  const { addMessage } = useToasterHandler();

  useEffect(() => {
    const currentDisplay = SettingsProvider.get("displayMode");
    console.log({ currentDisplay, device });
    if (device === Device.Mobile && currentDisplay !== "Mobile") {
      SettingsProvider.set("displayMode", "Mobile");
    } else if (device === Device.Tablet && currentDisplay !== "Tablet") {
      SettingsProvider.set("displayMode", "Tablet");
    } else if (device === Device.Desktop && currentDisplay !== "Web") {
      SettingsProvider.set("displayMode", "Web");
    }
  }, [device]);

  useEffect(() => {
    const currentDisplay = Settings.displayMode;
    console.log({ currentDisplay, device });
    if (device === Device.Mobile && currentDisplay !== "Mobile") {
      Settings.set("displayMode", "Mobile");
    } else if (device === Device.Tablet && currentDisplay !== "Tablet") {
      Settings.set("displayMode", "Tablet");
    } else if (device === Device.Desktop && currentDisplay !== "Web") {
      Settings.set("displayMode", "Web");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Settings.set, Settings.displayMode, device]);

  useEffect(() => {
    const display = SettingsProvider.get("displayMode");
    console.log(Settings.displayMode, display);
  }, [Settings.displayMode]);

  const viewProps = useMemo(() => ({ sw, account, device, trackEvent }), [account, device, sw, trackEvent]);
  const viewLoadingComponent = useMemo(
    () => () =>
      (
        <div className="inline-flex w-full h-full bg-white justify-center items-center content-start">
          <LoadingIndicator label="Lädt Auth. View" />
        </div>
      ),
    []
  );

  useEffect(() => {
    const now = new Date();
    trackEvent({
      data: "AppShell Loaded",
      identifier: "AppShell",
      isError: false,
      progress: "Ended",
      timestamp: now,
      timestampString: now.toISOString(),
      type: "Component",
      currentUrl: window.location.href,
      user: account?.username ?? account?.name ?? "???",
      version: appPackage.version,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEvent]);

  const goToAppsMkGk = () => {
    window.location.replace("https://www.mk-gk.de/apps.html");
  };

  const checkDeprecatedUrl = useCallback(() => {
    try {
      const url = document.location.hostname;

      const isBackend = url.toLocaleLowerCase().includes("backend");
      const isKonzept = url.toLocaleLowerCase().includes("konzept");
      const isDeprecated = isBackend || isKonzept;
      Settings.set("isDepreactedUrl", isDeprecated);
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Settings.set]);

  useEffect(() => {
    checkDeprecatedUrl();
  }, [checkDeprecatedUrl]);

  useEffect(() => {
    const showMessage = () => {
      return addMessage({
        color: "error",
        icon: "ShieldExclamationIcon",
        title: "Nicht unterstützte URL",
        text: `Sie nutzen aktuell eine URL die nicht länger unterstützt wird\r\n\r\n${window.location.hostname} ist nicht Prod1 oder Prod2\r\n\r\nBitte rufen Sie mk-gk.de/apps.html auf und wählen Sie dort eine Version`,
        delay: 600000,
        onClick: goToAppsMkGk,
      });
    };
    if (Settings.isDepreactedUrl) {
      setTimeout(() => {
        showMessage();
      }, 2500);
      const interval = setInterval(() => showMessage(), 1 * 60 * 1000);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [Settings.isDepreactedUrl, addMessage]);

  return (
    <AppInsightsWrapper>
      <TrackContext.Provider value={trackEvent}>
        <BrowserRouter>
          <UnauthenticatedTemplate>
            <UnauthenticatedMainView />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <TastaturContextProvider>
              <Route path="/login">
                <div className="inline-flex w-full h-full bg-white justify-center items-center content-start gap-1.5">
                  <h3 className="text-lg text-gray-800">Login abgeschlossen</h3>
                  <span className="animate-pulse text-sm text-gray-600">Sie werden weitergeleitet...</span>
                  <Redirect to="/Reservierung" exact />
                </div>
              </Route>
              <Suspense fallback={viewLoadingComponent}>
                <AuthenticatedView account={account} sw={sw} device={device} trackEvent={trackEvent} />
              </Suspense>
              {/* <AuthenticatedView sw={sw} account={account} device={device} /> */}
            </TastaturContextProvider>
            <div
              id="colorGrabber"
              className={`fixed top-0 left-0 min-w-0 min-h-0 w-px h-px bg-primary-500 flex-grow-0`}
            ></div>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </TrackContext.Provider>
    </AppInsightsWrapper>
  );
};
