import { SilentRequest, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsalAuthentication, useMsal, MsalContext, useAccount } from "@azure/msal-react";
import { useMemo, useEffect, useContext, useCallback } from "react";
import AuthConfig from "../../../AuthConfig";
import { useSavedAccount } from "../../../hooks/useSavedAccount";
import { WelcomeViewTypes } from "./WelcomeViewTypes";

export const useWelcomeViewModel = (props: WelcomeViewTypes.Props) => {
  const { instance } = useMsal();
  const [acc, setAccount] = useSavedAccount();
  const account = useAccount(instance.getActiveAccount() || acc || instance.getAllAccounts().find((c) => c) || {});

  const config: SilentRequest = useMemo(
    () =>
      ({
        ...AuthConfig.loginRequest,
        account: account ?? undefined,
        loginHint:
          account?.username ??
          ((account?.idTokenClaims as any)?.emails
            ? (account?.idTokenClaims as any)?.emails[0] ?? undefined
            : undefined) ??
          undefined,
      } as SilentRequest),
    [account]
  );
  const { result, error, login } = useMsalAuthentication(InteractionType.Silent, config);

  useEffect(() => {
    if (result) {
      setAccount(result.account);
    }
    if (error) {
      console.error(error);
    }
  }, [config, error, instance, login, result, setAccount]);

  useEffect(() => {
    instance.handleRedirectPromise().then((resp) => {
      if (resp?.account) setAccount(resp.account);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.handleRedirectPromise]);

  return {};
};
