import { Configuration } from "@azure/msal-browser";
import B2C from "./b2cpolicies";

const CLIENT_ID = B2C.isDev ? "80113c2b-08c3-4fe2-9abc-7569d4043c9a" : "33832427-3e24-4ca4-a310-c0808ed5db97";

const base: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    redirectUri: window.location.origin,
    authority: B2C.policies.authorities.signIn,
    knownAuthorities: [B2C.authorityDomain, B2C.authoritySubDomain],
  },

  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

console.log(base);

const loginRequest = {
  scopes: ["openid", "offline_access"],
};

const apiRequest = {
  reservierungen: {
    scopes: [
      `https://${B2C.authoritySubDomain}/${
        B2C.isDev ? "ReservierungsApp" : "ReservierungsApp"
      }/Reservierungen.ReadWrite`,
    ],
  },
};

const graphRequest = {
  scopes: [],
};

const AuthConfig = { base, loginRequest, graphRequest, apiRequest };

export default AuthConfig;
