import { FC, useEffect, useRef, useState } from "react";
import Tastatur from "./Tastatur";
import TastaturContext from "./TastaturContext";
import useTastatur from "./useTastatur";

type TastaturContextProviderProps = {};

const TastaturContextProvider: FC<TastaturContextProviderProps> = ({ children, ...props }) => {
  const TastaturData = useTastatur();

  return (
    <TastaturContext.Provider value={TastaturData}>
      <div
        id="TastaturScrollContainer"
        className={
          "inline-flex w-full h-full min-h-0 justify-start items-start content-start transition-transform duration-200 ease-in-out"
        }
      >
        {children}
      </div>

      <Tastatur
        type={TastaturData.type}
        onKeyPress={TastaturData.onKeyPress}
        isOpen={TastaturData.isVisible}
        onClose={TastaturData.hide}
        onOpen={TastaturData.show}
      />
    </TastaturContext.Provider>
  );
};
export default TastaturContextProvider;
